import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { useAuthStore, useDisciplineStore, useServicesStore, useStudentOrganizationStore } from '@/store';
import { can, useDateTime } from '@/composables';
import { CALENDAR_FILTER_DEFAULTS, CALENDAR_STATUS_FILTERS, EVENT_TYPE, GOAL_TYPE, PERMISSIONS } from '@/enum';
import { CalendarService, OrganizationService } from '@/services';
import {
  Attendee,
  AttendeeTherapyResults,
  ClinicianInterface,
  DetailedEventInterface,
  DisciplineInterface,
  EventTotalsInterface,
  FiltersTherapyEventsInterface,
  GoalInterface,
  GoalMeasurementsInterface,
  OrganizationsParentsInterface,
  PastResultInterface,
  ServiceInterface,
  TherapyEventAttendeeInterface,
  TherapyResultsInterface,
  TherapyResultsPayloadInterface,
  TherapyResultTypeInterface,
  TrialGoalInterface,
} from '@/types';
import { EventContentArg, EventInput } from '@fullcalendar/core';

interface EventInputResponseInterface {
  id: string;
  start_at: string;
  end_at: string;
  host_id: number;
  host_timezone_abbr: string;
  host_timezone_name: string;
  title: string;
  discipline_id?: number;
  discipline_code?: string;
  attendees: TherapyEventAttendeeInterface[];
  completed: boolean;
  therapy_event_type: string;
  rrule?: string;
  duration?: number;
  exdate?: Date[] | string[];
  organization_id: number;
  classroom?: {
    service_provider_name: string;
    classroom_link: string;
  };
}

interface EventInputExtended extends EventInput {
  eventType?: string;
  clinician?: number;
  eventStatus?: string;
  discipline?: number;
  disclipine_code?: string;
  organization?: number;
  attendees: Record<string, number | string>[];
  classroom?: {
    service_provider_name: string;
    classroom_link: string;
  };
}

export default defineStore('CalendarStore', {
  state: () => {
    return {
      currentCalendarView: '' as string,
      loading: true,
      error: '',
      events: {} as EventInputExtended[],
      rawEvents: {} as EventInputExtended[],
      therapyResultTypes: [] as TherapyResultTypeInterface[] | undefined,
      redirectedToDate: undefined as undefined | Date,
      evenToOpen: 0,
      startDate: '',
      endDate: '',
      attendeeId: 0,
      selectedEvent: {} as DetailedEventInterface,
      filters: {
        eventType: {
          options: [] as string[],
          selected: CALENDAR_FILTER_DEFAULTS.EVENT_TYPE,
          loading: false,
          default: CALENDAR_FILTER_DEFAULTS.EVENT_TYPE,
        },
        discipline: {
          options: [] as { name: string; value: number }[],
          selected: 0,
          loading: false,
          default: {
            name: CALENDAR_FILTER_DEFAULTS.DISCIPLINE as string,
            value: 0,
          },
        },
        eventStatus: {
          options: [] as string[],
          selected: CALENDAR_FILTER_DEFAULTS.STATUS,
          loading: false,
          default: CALENDAR_FILTER_DEFAULTS.STATUS,
        },
        organization: {
          options: [] as OrganizationsParentsInterface[],
          selected: {} as Record<number, boolean>,
          loading: false,
          default: {},
        },
        students: {
          options: [] as string[],
          selected: [] as string[],
          loading: false,
          default: [],
        },
        // this is not currently being used
        clinician: {
          options: [] as { name: string | undefined; value: number | null }[],
          selected: 0,
          loading: false,
          default: {
            name: CALENDAR_FILTER_DEFAULTS.CLINICIAN as string | undefined,
            value: 0,
          },
        },
      },
      documentedHoursDateRange: ['', ''],
      documentedHours: {
        planned: 0,
        documented: 0,
      } as EventTotalsInterface,
      pastDocumentations: [] as PastResultInterface[],
      pastDocumentationsLoading: false,
      pastDocumentationsPaginateState: [] as string[],
    };
  },
  getters: {
    isCaseloadManagement: () => (eventType: string) => eventType === EVENT_TYPE.CASELOAD_MANAGEMENT,

    isDayView: (state) => state.currentCalendarView === 'timeGridDay',

    isIEPMeeting: () => (eventType: string) => eventType === EVENT_TYPE.IEP_MEETING,

    /**
     * combine attendee and therapyResults info
     */
    attendeeTherapyResults: (state) => {
      const attendeeTherapyResults: AttendeeTherapyResults[] = [];

      //loop through the attendees to match with therapy_results
      state.selectedEvent.attendees?.forEach((attendee: Attendee) => {
        attendee.documentGoals = false;
        const attendeeTherapy: AttendeeTherapyResults = {
          attendee: attendee,
          therapy_results: undefined,
        };

        //find their therapy results by attendee.id && result.student_id
        const results = state.selectedEvent.therapy_results.find((result) => {
          return result.student_id === attendee.attendee_id;
        });

        attendeeTherapy.therapy_results = results;
        attendeeTherapyResults.push(attendeeTherapy);
      });

      return attendeeTherapyResults;
    },

    /**
     * returns the number of filters applied to the calendar
     */
    filtersAppliedCount(state): number {
      if (!this.isFilterSet) return 0;
      const { eventType, eventStatus, organization, discipline } = state.filters;
      const conditions = [
        eventType.selected !== CALENDAR_FILTER_DEFAULTS.EVENT_TYPE,
        eventStatus.selected !== CALENDAR_FILTER_DEFAULTS.STATUS,
        discipline.selected,
        Object.keys(organization.selected).length !== 0,
        // if product determines they want to have selected students count towards the filters applied
        // integer, uncomment the line below and add students to the state.filters destructuring
        // students.selected.length !== 0,
      ];
      let count = 0;

      conditions.forEach((condition) => {
        if (condition) count++;
      });
      return count;
    },
    /**
     * returns a function that accepts a student_id number to retrieve an Attendee object
     */
    findAttendeeById: (state): ((student_id: number) => Attendee | undefined) => {
      return (student_id: number) =>
        state.selectedEvent.attendees.find((attendee: Attendee) => attendee.attendee_id === student_id);
    },
    /**
     * determines whether a filter is currently set
     */
    isFilterSet: (state): boolean => {
      const { eventType, eventStatus, organization, discipline, students } = state.filters;
      return !!(
        eventType.selected !== CALENDAR_FILTER_DEFAULTS.EVENT_TYPE ||
        eventStatus.selected !== CALENDAR_FILTER_DEFAULTS.STATUS ||
        discipline.selected ||
        Object.keys(organization.selected).length !== 0 ||
        students.selected.length !== 0
      );
    },
    searchFilterSuggestions: (state): string[] => {
      if (!state.rawEvents.length) return [];

      const suggestions: Set<string> = new Set();
      state.rawEvents.forEach(
        (event) =>
          event.title &&
          !state.filters.students.selected.includes(event.title) &&
          suggestions.add(event.title as string),
      );

      return Array.from(suggestions);
    },
  },
  actions: {
    resetEvents() {
      this.events = [];
      this.rawEvents = [];
      this.clearFilters();
    },
    catchError(e: AxiosResponse) {
      this.error = 'There was an error';
      this.loading = false;
      console.error(e);
    },

    async setDefaultFilters() {
      const { organization } = this.filters;
      let refetch = false;

      if (Object.keys(organization.selected).length) refetch = true;

      this.clearFilters();

      if (!refetch) await this.filterEvents();
      else await this.getEvents({ start_at: this.startDate, end_at: this.endDate });
    },
    async buildEvents(response: AxiosResponse) {
      if (response.data.data) {
        this.rawEvents = response.data.data.map((e: EventInputResponseInterface) => this.buildEvent(e));
      }
    },
    /**
     * buildEventRRule
     *
     * When an event is documented, the event loses it's Rrule property. This creates a new one based
     * upon the start_at and end_at strings instead.
     *
     * @param start_at {string}
     * @param end_at {string}
     */
    buildEventRRule(start_at: string, end_at: string): RRule {
      const tempStartArr: string[] = start_at.split(' ');
      const startArr: number[] = tempStartArr[0]
        .split('-')
        .concat(tempStartArr[1].split(':'))
        .map((num) => parseInt(num));
      const tempEndArr: string[] = end_at.split(' ');
      const endArr: number[] = tempEndArr[0]
        .split('-')
        .concat(tempEndArr[1].split(':'))
        .map((num) => parseInt(num));

      // notice the startArr[1] - 1 and endArr[1] - 1, Date.UTC()'s month parameter is 0 based, so
      // january is actually 0, december is 11 etc.
      const start = new Date(
        Date.UTC(startArr[0], startArr[1] - 1, startArr[2], startArr[3], startArr[4], startArr[5], 0),
      );
      const end = new Date(Date.UTC(endArr[0], endArr[1] - 1, endArr[2], endArr[3], endArr[4], endArr[5], 0));

      const eventRRule = new RRule({
        dtstart: start,
        freq: RRule.WEEKLY,
        count: 1,
        until: end,
      });
      return eventRRule;
    },

    buildEvent(e: EventInputResponseInterface) {
      const {
        title,
        id,
        rrule,
        duration,
        start_at,
        end_at,
        completed,
        therapy_event_type,
        host_id,
        host_timezone_abbr,
        host_timezone_name,
        discipline_id,
        discipline_code,
        organization_id,
        attendees,
        classroom,
      } = e;

      let eventRRule: RRule | RRuleSet;

      //if NO rrule then its a single day event which goes by start, date, and duration
      if (!rrule) {
        eventRRule = this.buildEventRRule(start_at, end_at);
      } else {
        eventRRule = rrulestr(rrule);
      }

      const event: EventInput = {
        attendees: attendees.map((attendeeObject) => attendeeObject.attendee),
        title,
        id,
        organization: organization_id,
        rrule: eventRRule.toString(),
        duration: {
          minutes: duration,
        },
        classNames: this.buildEventClassName(completed, therapy_event_type),
        clinician: host_id,
        hostTimezone: host_timezone_abbr,
        hostTimezoneName: host_timezone_name,
        discipline: discipline_id,
        discipline_code: discipline_code,
        eventStatus: completed ? 'documented' : 'undocumented',
        eventType: therapy_event_type,
        classroom,
      };

      return event;
    },

    /**
     * build the classNames array in Events based on completed bool and therapy_event_type
     * @param completed boolean
     * @param type string
     * @returns classNames array
     */
    buildEventClassName(completed: boolean, type: string) {
      const documented = completed ? 'documented' : 'undocumented';
      const eventType = type.replace(/\s+/g, '-').toLowerCase();
      return [eventType, documented];
    },

    async getEvents(filtersEvent: FiltersTherapyEventsInterface) {
      this.loading = true;

      if (!can([PERMISSIONS.THERAPY_EVENT_INDEX])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view calendar events';
        console.error(this.error);
        return;
      }

      this.startDate = filtersEvent.start_at;
      this.endDate = filtersEvent.end_at;
      this.loading = true;
      const response = await CalendarService.getEvents(filtersEvent).catch(this.catchError);
      this.documentedHours = response?.data?.meta.totals;
      if (response) await this.buildEvents(response);
      await this.filterEvents();

      this.loading = false;
    },

    clearFilters() {
      const { eventType, eventStatus, organization, discipline, students } = this.filters;

      eventType.selected = eventType.default;
      eventStatus.selected = eventStatus.default;
      organization.selected = organization.default;
      discipline.selected = discipline.default.value;
      students.selected = students.default;
    },

    async getEventsByOrganization(start: string, end: string, organizationIds: Array<number>) {
      if (!can([PERMISSIONS.THERAPY_EVENT_INDEX_FILTERS])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view/filter calendar events';
        console.error(this.error);
        return;
      }

      this.startDate = start;
      this.endDate = end;
      this.loading = true;

      const response = await CalendarService.getEventsByOrganization(start, end, organizationIds).catch(
        this.catchError,
      );
      if (response) await this.buildEvents(response);
      this.loading = false;
      await this.filterEvents();
    },

    async storeUpdateTherapyResults(payload: TherapyResultsPayloadInterface, originalAttendees: Attendee[]) {
      if (
        !can([
          PERMISSIONS.THERAPY_RESULTS_BULK_UPDATE,
          PERMISSIONS.THERAPY_RESULTS_DAY_UPDATE,
          PERMISSIONS.THERAPY_RESULTS_STORE,
        ])
      ) {
        this.loading = false;
        this.error = 'Insufficient permissions to alter therapy results.';
        console.error(this.error);
        return [];
      }

      if (this.loading) return;

      this.loading = true;
      const documentedAttendeesIds: number[] = [];

      for (const data of payload.therapy_results) {
        if (data.therapy_result_type_id === undefined) {
          documentedAttendeesIds.push(data.attendee_id as number);
          continue;
        }

        data.goals_measurements?.forEach((gm) => {
          const attendee = this.selectedEvent.attendees.filter((a) =>
            a.goals?.find((g) => g.id == gm.treatment_goal_id),
          )[0];

          const goal = attendee.goals?.find((g) => g.id == gm.treatment_goal_id);
          if (goal?.treatment_goal_type.title === GOAL_TYPE.PERCENTAGE) {
            delete gm.total_trials;
            delete gm.trials;
            delete gm.checkbox_order;
          }
        });

        documentedAttendeesIds.push(data.attendee_id as number);

        if (!data.new_attendee) {
          delete data.new_attendee;
        }
      }

      const originalNotSelecteds: Attendee[] = originalAttendees.filter(
        (a) => !documentedAttendeesIds.includes(a.attendee_id),
      );

      for (const attendeeNotSelected of originalNotSelecteds) {
        payload.therapy_results.push(<TherapyResultsInterface>{
          remove_attendee: true,
          attendee_id: attendeeNotSelected.attendee_id,
        });
      }

      payload.therapy_results = payload.therapy_results.filter(
        (data) => data.therapy_result_type_id !== undefined || data.remove_attendee === true,
      );

      const response = await CalendarService.storeUpdateTherapyResults(
        payload,
        this.selectedEvent.event_process_type,
      ).catch((response) => response);
      this.loading = false;

      return response;
    },

    async getTherapyResultTypes() {
      this.loading = true;

      if (!can([PERMISSIONS.THERAPY_RESULT_TYPES_INDEX])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view therapy result types.';
        console.error(this.error);
        return;
      }

      const response = await CalendarService.getTherapyResultTypes().catch(this.catchError);
      this.therapyResultTypes = response?.data.data.sort((a: TherapyResultTypeInterface) => (a.is_present ? -1 : 0));
      this.loading = false;
    },

    async getEvent(id: string) {
      this.loading = true;

      if (!can([PERMISSIONS.THERAPY_EVENT_INDEX])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view calendar events';
        console.error(this.error);
        return Promise.reject();
      }

      const response = await CalendarService.getEvent(id).catch(this.catchError);
      this.selectedEvent = response?.data.data;
      this.selectedEvent.is_past = useDateTime().fromUTCStringToLocalDate(this.selectedEvent.start_at) < new Date();

      this.selectedEvent.display_start_at = this.selectedEvent.start_at;
      this.selectedEvent.display_end_at = useDateTime().setDateStringToSpecificDate(
        this.selectedEvent.display_start_at,
        this.selectedEvent.end_at,
      );
      this.loading = false;

      if (this.selectedEvent.is_past) this.createTherapyResultContainer();
      this.loading = false;
      return this.selectedEvent;
    },

    createTherapyResultContainer() {
      this.selectedEvent.attendees.forEach((attendee) => {
        const attendeeTherapyResult: TherapyResultsInterface = {
          student_id: attendee.attendee_id,
          therapy_attendee_id: attendee.id,
          event_id: this.selectedEvent.id,
          notes: '',
          duration: this.selectedEvent.duration,
          therapy_result_type: { id: undefined },
          goals_measurements: [],
        };
        //if the attendee has goals then make sure there is a companion in therapy_results.goal_measurements
        if (attendee.goals?.length) {
          //find their therapy results by attendee.id && result.student_id.
          const results = this.selectedEvent.therapy_results.find(
            (result) => result.student_id === attendee.attendee_id,
          );
          //if no results then create a container for each attendee result
          if (!results) {
            //create goal measurements for each goal
            attendee.goals?.forEach((goal) => {
              attendeeTherapyResult.goals_measurements.push(this.createGoalMeasurement(goal));
            });
            this.selectedEvent.therapy_results.push(attendeeTherapyResult);
          } else {
            //if there are results make sure there are goal measurements for each goal
            attendee.goals.forEach((goal) => {
              const measurement = results.goals_measurements.find((m) => m.treatment_goal_id === goal.id);
              if (!measurement) {
                results.goals_measurements.push(this.createGoalMeasurement(goal));
              } else {
                measurement.treatment_goal_type_id = goal.treatment_goal_type_id;
              }
            });
          }
        } else {
          this.selectedEvent.therapy_results.push(attendeeTherapyResult);
        }
      });
    },

    createGoalMeasurement(goal: GoalInterface) {
      const goalMeasurement: TrialGoalInterface | GoalMeasurementsInterface = {
        treatment_goal_id: goal.id,
        percentage: 0,
        description: '',
        show_goal: false,
        component: undefined,
        treatment_goal_type_id: goal.treatment_goal_type_id,
      };

      if (goal.treatment_goal_type.title === GOAL_TYPE.TRIALS) {
        goalMeasurement.checkbox_order = [];
        goalMeasurement.total_trials = 10;
        goalMeasurement.trials = 0;
      }

      return goalMeasurement;
    },

    async filterEvents() {
      if (!this.isFilterSet) {
        this.events = this.rawEvents;
        return;
      }

      if (!can([PERMISSIONS.THERAPY_EVENT_INDEX_FILTERS])) {
        this.loading = false;
        this.error = 'Insufficient permissions to filter calendar events';
        console.error(this.error);
        return;
      }

      const { organization, students } = this.filters;
      if (students.selected.length) {
        const studentId = this.rawEvents
          .find((event) => {
            return event.attendees.some((attendee) => attendee.display_name === students.selected[0]);
          })
          ?.attendees.find((attendee) => attendee?.display_name === students.selected[0])?.id;

        if (studentId) {
          const org = await useStudentOrganizationStore().getOrganizationsForStudent(Number(studentId));

          if (org?.id) organization.selected[org.id] = true;
        }
      }

      if (Object.keys(organization.selected).length) {
        const organizationIds = Object.keys(organization.selected).map((key) => parseInt(key));

        const response = await CalendarService.getEventsByOrganization(this.startDate, this.endDate, organizationIds);
        await this.buildEvents(response);
      }

      this.setEventsFromFilteredRawEvents();
    },
    searchStudentSuggestions(searchString: string) {
      if (!searchString.length) {
        this.filters.students.options = [...this.searchFilterSuggestions];
      } else {
        this.filters.students.options = this.searchFilterSuggestions.filter((student: string) => {
          return student.toLowerCase().includes(searchString.toLowerCase());
        });
      }
    },
    setEventsFromFilteredRawEvents() {
      const { eventType, eventStatus, discipline, students } = this.filters;

      this.events = this.rawEvents.filter((event) => {
        if (eventType.selected !== CALENDAR_FILTER_DEFAULTS.EVENT_TYPE) {
          if (eventType.selected !== event.eventType) {
            return false;
          }
        }
        if (eventStatus.selected !== CALENDAR_FILTER_DEFAULTS.STATUS) {
          if (eventStatus.selected.toLowerCase() !== event.eventStatus) {
            return false;
          }
        }
        if (discipline.selected) {
          if (discipline.selected !== event.discipline) {
            return false;
          }
        }
        if (students.selected.length) {
          return students.selected.some((student) => String(event?.title).includes(student));
        }

        return true;
      });
    },
    async getClinicians() {
      this.filters.clinician.loading = true;

      if (!can([PERMISSIONS.ORGANIZATION_CLINICIANS_INDEX])) {
        this.filters.clinician.loading = false;
        this.error = 'Insufficient permissions to view Service Providers';
        console.error(this.error);
        return;
      }

      const hasOrganizationId = !!useAuthStore().organizationId;

      if (hasOrganizationId) {
        const organizationId = useAuthStore().organizationId as number;
        const filterPerson = '';
        const request = await OrganizationService.getPerson(organizationId, 'clinicians', filterPerson);
        const clinicians = request.data?.data.map((item: ClinicianInterface) => {
          return { name: item.display_name, value: item.id as number | null };
        });
        clinicians.unshift(this.filters.clinician.default);
        this.filters.clinician.loading = false;
        this.filters.clinician.options = clinicians;
        this.filters.clinician.selected = this.filters.clinician.default.value;
      }
    },

    async getServices() {
      this.filters.eventType.loading = true;

      if (!can([PERMISSIONS.SERVICES_INDEX])) {
        this.filters.eventType.loading = false;
        this.error = 'Insufficient permissions to view services';
        console.error(this.error);
        return;
      }

      const request = await useServicesStore().getServices();
      const services = request?.map((item: ServiceInterface) => item.name) || [];
      services.unshift(CALENDAR_FILTER_DEFAULTS.EVENT_TYPE);
      this.filters.eventType.loading = false;
      this.filters.eventType.options = services;
      this.filters.eventType.selected = CALENDAR_FILTER_DEFAULTS.EVENT_TYPE;
    },

    async getDisciplines() {
      this.filters.discipline.loading = true;

      if (!can([PERMISSIONS.DISCIPLINES_INDEX])) {
        this.filters.discipline.loading = false;
        this.error = 'Insufficient permissions to view disciplines';
        console.error(this.error);
        return;
      }

      const request = await useDisciplineStore().getDisciplines();
      const disciplines =
        request?.map((item: DisciplineInterface) => {
          return { name: item.name, value: item.id };
        }) || [];
      disciplines?.unshift(this.filters.discipline.default);
      this.filters.discipline.loading = false;
      this.filters.discipline.options = disciplines;
      this.filters.discipline.selected = this.filters.discipline.default.value;
    },

    getEventStatuses() {
      this.filters.eventStatus.loading = true;
      const statuses = Array.from(CALENDAR_STATUS_FILTERS);
      statuses.unshift(CALENDAR_FILTER_DEFAULTS.STATUS);
      this.filters.eventStatus.loading = false;
      this.filters.eventStatus.options = statuses;
      this.filters.eventStatus.selected = CALENDAR_FILTER_DEFAULTS.STATUS;
    },

    async getOrganizations() {
      this.filters.organization.loading = true;

      if (!can([PERMISSIONS.ORGANIZATIONS_INDEX])) {
        this.filters.organization.loading = false;
        this.error = 'Insufficient permissions to view organizations';
        console.error(this.error);
        return;
      }

      const request = await useAuthStore().getOrganizations();
      const organizations = request;
      this.filters.organization.loading = false;
      this.filters.organization.options = organizations;
      this.filters.organization.selected = {};
    },

    clearPastDocumentations() {
      this.pastDocumentations = [] as PastResultInterface[];
      this.pastDocumentationsLoading = false;
      this.pastDocumentationsPaginateState = [] as string[];
    },

    updatePastDocumentationState(attendee_id: number, date: string) {
      if (this.pastDocumentationsPaginateState[attendee_id] !== '') {
        this.pastDocumentationsPaginateState[attendee_id] = date;
      }
    },

    getRedirectedDate() {
      const date = this.redirectedToDate;
      this.redirectedToDate = undefined;
      return date;
    },

    async getPastDocumentations(event_date: string, attendee_id: number) {
      if (!can([PERMISSIONS.THERAPY_RESULTS_INDEX])) {
        this.error = 'Insufficient permissions to view past documented events';
        console.error(this.error);
        return;
      }

      if (this.pastDocumentationsPaginateState[attendee_id] === '' || this.pastDocumentationsLoading) {
        return;
      }

      this.pastDocumentationsLoading = true;
      let firstFetch = false;

      if (!this.pastDocumentationsPaginateState[attendee_id]) {
        this.pastDocumentationsPaginateState[attendee_id] = this.selectedEvent.document_date as string;
        firstFetch = true;
      }

      const response = await CalendarService.getPastDocuments(
        this.selectedEvent.id,
        event_date,
        attendee_id,
        this.pastDocumentationsPaginateState[attendee_id],
      );
      this.pastDocumentations.push(...response.data.data);
      this.pastDocumentationsLoading = false;

      if (response.data.data.length < 10 && !firstFetch) {
        this.pastDocumentationsPaginateState[attendee_id] = '';
      }
    },

    defineEventsClassNames(arg: EventContentArg) {
      const documented = arg.event?._def?.extendedProps?.eventStatus === 'documented';

      if (arg.event?._instance?.range) {
        const startInUTCArray = new Date(arg.event._instance.range.start).toISOString().split(/[-|T|:|.]/gm);
        const startAtUTC = new Date(
          parseInt(startInUTCArray[0]),
          parseInt(startInUTCArray[1]) - 1,
          parseInt(startInUTCArray[2]),
          parseInt(startInUTCArray[3]),
          parseInt(startInUTCArray[4]),
          parseInt(startInUTCArray[5]),
        );
        if (startAtUTC < new Date() || documented) {
          return ['fc-event-past'];
        }
      }

      return ['fc-event-future'];
    },
  },
});
