import { defineStore } from 'pinia';
import { can, useArrayUtilities } from '@/composables';
import { PERMISSIONS } from '@/enum';
import { AttendanceService, TherapyService } from '@/services';
import { ComplianceStudent, StudentAttendanceInterface } from '@/types';

const arrayUtilities = useArrayUtilities();

interface PreferenceInterface {
  id: number;
  person_id: number;
  name: string;
  value: string;
}

export default defineStore('AttendanceStore', {
  state: () => {
    return {
      loading: false,
      error: '',
      start_at: '',
      end_at: '',
      lowAttendance: [] as ComplianceStudent[],
      outOfLowAttendance: [] as ComplianceStudent[],
      results: [],
      therapyResults: [],
      resultsTotal: 0,
      lowAttendanceThreshold: 0,
      allPercentage: 0,
      cloneResults: [],
      cloneResultsTotal: 0,
      cloneAllPercentage: 0,
      usingClone: true,
      studentName: 'All',
      filteredStudent: null as StudentAttendanceInterface | null,
    };
  },

  actions: {
    setDefaultDates() {
      const userDateInterval = [-28, 0];
      const start = new Date();
      const end = new Date();
      end.setDate(end.getDate() + userDateInterval[1]);
      start.setDate(start.getDate() + userDateInterval[0]);
      this.setDates(start.toJSON().split('T')[0], end.toJSON().split('T')[0]);
    },

    setDates(startDate: string, endDate: string) {
      this.start_at = startDate;
      this.end_at = endDate;
    },

    async getLowAttendanceDateRangePreference(personId: number) {
      if (!can([PERMISSIONS.WIDGET_ATTENDANCE_LOW_ATTENDANCE, PERMISSIONS.WIDGET_ATTENDANCE_ALL])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view low attendance data.';
        console.error(this.error);
        return Promise.reject();
      }

      this.loading = true;
      const response = await AttendanceService.getLowAttendanceDateRangePreference(personId);
      const preference = response.data.data.find(
        (preference: PreferenceInterface) => preference.name === 'low_attendance_date_range',
      );
      this.loading = false;
      return preference ? preference.value.split(',').map((days: string) => Number(days)) : [-28, 0];
    },

    async onDateRangeChange(organizationsId?: number[]) {
      await this.getAllAttendance(organizationsId);
    },

    async getAllAttendance(organizationsId?: number[]) {
      if (!can([PERMISSIONS.WIDGET_ATTENDANCE_ALL])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view attendance data.';
        console.error(this.error);
        return Promise.reject();
      }

      if (!this.start_at.length && !this.end_at.length) {
        this.setDefaultDates();
      }

      this.loading = true;

      await AttendanceService.getAll({ start_at: this.start_at, end_at: this.end_at }, organizationsId || []).then(
        (response) => {
          this.lowAttendance = arrayUtilities.useValueNameSort(response.data.data.low_attendance);
          this.outOfLowAttendance = arrayUtilities.useValueNameSort(response.data.data.out_of_low_attendance);
          this.lowAttendanceThreshold = response.data.data.low_attendance_threshold;
          this.allPercentage = response.data.data.all.percentage;
          this.studentName = 'All';

          this.results = response.data.data.attendance.results;
          this.resultsTotal = response.data.data.attendance.total;

          this.cloneResults = response.data.data.attendance.results;
          this.cloneResultsTotal = response.data.data.attendance.total;
          this.cloneAllPercentage = response.data.data.all.percentage;
          this.usingClone = true;

          this.loading = false;
        },
      );

      if (this.filteredStudent?.id) {
        await this.getAttendanceResults(this.filteredStudent.id, this.filteredStudent?.full_name, organizationsId);
      }
    },

    async getAttendanceResults(attendee_id: number, studentName?: string, organizationsId?: number[]) {
      if (!can([PERMISSIONS.WIDGET_ATTENDANCE_RESULTS])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view attendance data.';
        console.error(this.error);
        return Promise.reject();
      }

      if (this.loading) return;

      this.loading = true;
      const response = await AttendanceService.getResults(
        attendee_id,
        {
          start_at: this.start_at,
          end_at: this.end_at,
        },
        organizationsId || [],
      );

      this.results = response.data.data.results.results;
      this.resultsTotal = response.data.data.results.total;
      this.studentName = studentName || '';

      this.usingClone = false;
      this.loading = false;
    },

    async getLowAttendance(threshold: number, organizationsId?: number[]) {
      if (!can([PERMISSIONS.WIDGET_ATTENDANCE_LOW_ATTENDANCE])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view low attendance data.';
        console.error(this.error);
        return Promise.reject();
      }

      await AttendanceService.getLowAttendance(
        threshold,
        {
          start_at: this.start_at,
          end_at: this.end_at,
        },
        organizationsId || [],
      )
        .then((response) => {
          this.lowAttendance = arrayUtilities.useValueNameSort(response.data.data.low_attendance);
          this.outOfLowAttendance = arrayUtilities.useValueNameSort(response.data.data.out_of_low_attendance);
          this.lowAttendanceThreshold = response.data.data.low_attendance_threshold;
          this.loading = false;
        })
        .catch(() => {
          this.loading = true;
          return;
        });
    },

    async getTherapyResults(attendee_id: number, start_at: string, end_at: string) {
      if (!can([PERMISSIONS.THERAPY_RESULTS_INDEX])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view therapy results.';
        console.error(this.error);
        return Promise.reject();
      }

      await TherapyService.getTherapyResults(attendee_id, start_at, end_at)
        .then((response) => {
          this.therapyResults = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          return;
        });
    },

    useCloneResults() {
      if (this.usingClone) return;
      this.loading = true;

      this.results = this.cloneResults;
      this.resultsTotal = this.cloneResultsTotal;
      this.allPercentage = this.cloneAllPercentage;
      this.studentName = 'All';
      this.usingClone = true;

      this.loading = false;
    },
  },
});
