/* eslint-disable */
// @ts-nocheck

import { useTenantSettingStore } from '@/store';

export const useTenant = () => {
  const tenantSettingEnabled = (settings: string) => {
    return useTenantSettingStore().settings.find((item) => item.name === settings)?.value == 1;
  };

  return { tenantSettingEnabled };
};
