import { RRule } from 'rrule';
import { useDateTime } from '@/composables';
import { EVENT_TYPE } from '@/enum';
import {
  ApiPromise,
  AttendeeDropdownOptionInterface,
  EventCreationFormInterface,
  EventDeleteRequestInterface,
} from '@/types';
import { HTTP } from '@/utils';

export default class TherapyService {
  static therapyResource = 'therapy';
  static therapySessionResource = 'therapy-sessions';
  static therapySessionTypesResource = 'therapy-session-types';
  static therapyEvaluationTypesResource = 'therapy-evaluation-types';
  static therapyEventResource = 'therapy-event';
  static therapyResultsResource = 'therapy-results';
  static therapyResultTypesResource = 'therapy-result-types';
  static therapyServiceResource = 'therapy-service';
  static assessments = 'assessments';
  static therapyEventDateResource = `${this.therapyEventResource}/requirements`;

  static getTherapySessionTypes(): ApiPromise {
    return HTTP.get(this.therapySessionTypesResource);
  }

  static getTherapyEvaluationTypes(): ApiPromise {
    return HTTP.get(this.therapyEvaluationTypesResource);
  }

  static getEvaluationAssessmentsTypes(): ApiPromise {
    return HTTP.get(this.assessments);
  }

  static downloadEvaluationAssessmentsRecordForm(recordFormId: number): ApiPromise {
    return HTTP.get(`${this.assessments}/record-form/${recordFormId}`, {
      responseType: 'blob',
    });
  }

  static downloadEvaluationAssessmentsMaterial(materialId: number): ApiPromise {
    return HTTP.get(`${this.assessments}/material/${materialId}`, {
      responseType: 'blob',
    });
  }

  static getAllTherapies(): ApiPromise {
    return HTTP.get(this.therapyResource);
  }

  static createOrUpdateTherapyEvent(
    payload: EventCreationFormInterface,
    eventId?: number,
    eventTypeCondition?: string,
  ): ApiPromise {
    const requestData = new FormData();
    const rruleCopy: Partial<RRule> = payload.rrule as RRule;
    let dateOnlyUntil = new Date();
    if (rruleCopy && rruleCopy.options) {
      // must set the dates to UTC format to be sent to the API
      rruleCopy.options.dtstart = new Date((rruleCopy.options?.dtstart as Date).toUTCString());
      rruleCopy.options.until = new Date((rruleCopy.options?.until as Date).toUTCString());
      dateOnlyUntil = new Date(
        Date.UTC(
          rruleCopy.options.until.getUTCFullYear(),
          rruleCopy.options?.until?.getUTCMonth(),
          rruleCopy.options?.until?.getUTCDate(),
        ),
      );
      rruleCopy.options.tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // count is not supported currently in the API
      rruleCopy.options.count = null;
      rruleCopy.options.byhour = [];
      rruleCopy.options.byminute = [];
      rruleCopy.options.bysecond = [];
    }
    const start_at = useDateTime().formatDateForApiWithoutConvertTimezone(rruleCopy.options?.dtstart, true);
    const end_at = useDateTime().formatDateForApiWithoutConvertTimezone(rruleCopy.options?.until, true);

    // default form fields
    requestData.append('start_at', start_at as string);
    requestData.append('end_at', end_at as string);
    requestData.append('duration', payload.duration.toString());
    requestData.append('therapy_event_type', payload.therapy_event_type);
    // must remove the DTSTART from the string to be accepted
    if (!payload.rruleNull) {
      // for the monthly recurrence dropdown selection, some special handling needs to take place
      // as the byweekday property isn't the same between origOptions and the options
      if (rruleCopy.options?.bynweekday !== null && rruleCopy.options) {
        rruleCopy.options.byweekday = rruleCopy.origOptions?.byweekday as number[];
        rruleCopy.options.bynweekday = null;
      }
      (rruleCopy as RRule).options.until = dateOnlyUntil;
      const rrulestring = RRule.optionsToString((rruleCopy as RRule).options);
      requestData.append('rrule', rrulestring.split('\n')[1]);
    }
    // TODO api doesn't currently allow an event to be created without a discipline ID,
    // uncomment the code below this line and remove the other if else logic
    // if (Array.from(Object.values(DisciplineAttendeeRequiredEventTypes)).includes(payload.therapy_event_type)) {
    if (payload.discipline_id) {
      requestData.append('discipline_id', payload.discipline_id?.toString() as string);
    } else {
      requestData.append('discipline_id', '1');
    }

    const attendeeIds: string[] = [];

    if (Array.isArray(payload.attendees)) {
      (payload.attendees as AttendeeDropdownOptionInterface[]).forEach((attendee) => {
        requestData.append('attendees[]', attendee.value.toString());
        attendeeIds.push(attendee.value.toString());
      });
    } else {
      requestData.append('attendees[]', payload.attendees.value.toString());
      attendeeIds.push(payload.attendees.value.toString());
    }

    if (payload.description) {
      requestData.append('event_type_data[description]', payload.description);
    }

    if (payload.therapy_event_type === EVENT_TYPE.SESSION && Array.isArray(payload.session_types)) {
      const personProperty = Object.prototype.hasOwnProperty.call(payload.session_types[0], 'person_id')
        ? 'person_id'
        : 'attendee_id';
      payload.session_types.forEach((sessionObject, index) => {
        const attendee = sessionObject[personProperty] ? sessionObject[personProperty] : attendeeIds[index];

        if (attendee) {
          requestData.append(`event_type_data[session_types][${index}][${personProperty}]`, attendee.toString());
          requestData.append(
            `event_type_data[session_types][${index}][therapy_session_type_id]`,
            sessionObject.therapy_session_type.id.toString(),
          );
        }
      });
    }

    if (payload.therapy_caseload_management_types?.length) {
      payload.therapy_caseload_management_types.forEach((caseloadType) => {
        requestData.append('event_type_data[therapy_caseload_management_types][]', caseloadType.toString());
      });
    }

    if (payload.therapy_evaluation_type_id) {
      requestData.append('event_type_data[therapy_evaluation_type_id]', payload.therapy_evaluation_type_id.toString());
    }

    if (payload.assessment_record_form_id) {
      requestData.append('event_type_data[assessment_record_form_id]', payload.assessment_record_form_id.toString());
    }

    if (payload.therapy_event_type === EVENT_TYPE.EVALUATION && payload.due_at) {
      const due_at = useDateTime().formatDateForApi(payload.due_at, false);
      requestData.append('event_type_data[due_at]', due_at);
    }

    if (payload.documents) {
      payload.documents.forEach((document) => {
        requestData.append('documents[]', document);
      });
    }
    if (eventId) {
      requestData.append('event_process_type', eventTypeCondition as string);
      if (
        eventTypeCondition === 'this' &&
        payload.therapy_event_type !== EVENT_TYPE.CASELOAD_MANAGEMENT &&
        payload.rrule
      ) {
        requestData.delete('rrule');
      }

      return this.updateTherapyEvent(requestData, eventId);
    }
    return HTTP.post(this.therapyEventResource, requestData);
  }

  static getCaseloadManagementTypesRequest(): ApiPromise {
    return HTTP.get('therapy-caseload-management-types');
  }

  static updateTherapyEvent(payload: FormData, eventId: number): ApiPromise {
    return HTTP.post(`${this.therapyEventResource}/${eventId}?_method=put`, payload);
  }

  static deleteEventRequest(payload: EventDeleteRequestInterface) {
    const date = useDateTime().formatDateForApi(new Date(payload.date));
    const requestUrl = `${this.therapyEventResource}/${payload.id}/${date}/${payload.mode}`;
    return HTTP.delete(requestUrl);
  }

  static unlockTherapyEvent(id: number) {
    return HTTP.get(`${this.therapyEventResource}/${id}/unlock`);
  }

  static getTreatmentGoals(id: number): ApiPromise {
    return HTTP.get(`${this.therapyResultsResource}/${id}`);
  }

  static getTherapyResults(attendee_id: number, start_at: string, end_at: string): ApiPromise {
    return HTTP.get(`${this.therapyResultsResource}?attendee_id=${attendee_id}&start_at=${start_at}&end_at=${end_at}`);
  }

  static getTherapyResultTypes(): ApiPromise {
    return HTTP.get(this.therapyResultTypesResource);
  }

  static getTherapyEventAllowedDateRange(org_id: number, eventDate: string): ApiPromise {
    return HTTP.get(`${this.therapyEventDateResource}?organization_id=${org_id}&event_date=${eventDate}`);
  }
}
