import { defineStore } from 'pinia';
import download from 'downloadjs';
import { LocationQuery } from 'vue-router';
import { useOrganizationStore } from '@/store';
import { OrganizationDocumentsService } from '@/services';
import { OrganizationDocumentsInterface } from '@/types';

export default defineStore('OrganizationDocumentsStore', {
  state: () => {
    return {
      loading: true,
      error: false,
      message: '',
      document: {},
      billingDocuments: {
        data: [] as OrganizationDocumentsInterface[],
        total: 0,
        length: 0,
      },
      generalDocuments: {
        data: [] as OrganizationDocumentsInterface[],
        total: 0,
        length: 0,
      },
      catch: null as Error | null,
    };
  },

  actions: {
    load() {
      this.loading = true;
      this.error = false;
      this.message = '';
    },
    loaded() {
      if (!this.error) {
        this.loading = false;
        this.message = '';
      }
    },
    /**
     * Get the organizations  document tree data from the API and save it to state.
     * id: number, filters?: OrganizationDocumentsFilterInterface
     */
    async getBillingDocuments(filters?: LocationQuery) {
      this.load();
      if (useOrganizationStore().organization.id) {
        const response = await OrganizationDocumentsService.getOrganizationBillingDocuments(
          useOrganizationStore().organization.id,
          filters,
        ).catch(this.catchError);
        if (response) {
          this.billingDocuments.data = response.data.data;
          this.billingDocuments.total = response.data.meta.total;
        }
      }
      this.loaded();
    },
    async uploadBillingDocument(id: number, payload: FormData) {
      this.load();
      const result = await OrganizationDocumentsService.uploadBillingDocument(id, payload).catch(this.catchError);

      this.loaded();
      return result;
    },
    async downloadBillingDocument(id: number, name: string) {
      const response = await OrganizationDocumentsService.downloadBillingDocument(id).catch(this.catchError);
      const content = response.headers['content-type'];

      download(response.data, name, content);
    },
    async deleteBillingDocument(id: number) {
      await OrganizationDocumentsService.deleteBillingDocument(id, useOrganizationStore().organization.id);
    },
    async getGeneralDocuments(filters?: LocationQuery) {
      this.load();
      if (useOrganizationStore().organization.id) {
        const response = await OrganizationDocumentsService.getOrganizationGeneralDocuments(
          useOrganizationStore().organization.id,
          filters,
        ).catch(this.catchError);
        if (response) {
          this.generalDocuments.data = response.data.data;
          this.generalDocuments.total = response.data.data.length;
        }
      }
      this.loaded();
    },
    async uploadGeneralDocument(id: number, payload: FormData) {
      this.load();
      const result = await OrganizationDocumentsService.uploadGeneralDocument(id, payload).catch(this.catchError);

      this.loaded();
      return result;
    },
    async downloadGeneralDocument(id: number, name: string) {
      const response = await OrganizationDocumentsService.downloadGeneralDocument(
        id,
        useOrganizationStore().organization.id,
      ).catch(this.catchError);
      const content = response.headers['content-type'];

      return download(response.data, name, content);
    },
    async deleteGeneralDocument(id: number) {
      await OrganizationDocumentsService.deleteGeneralDocument(id, useOrganizationStore().organization.id);
    },

    catchError(e: Error) {
      this.loading = false;
      this.error = true;
      this.message = e.message;
      throw e;
    },
  },
});
