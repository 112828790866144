import { defineStore } from 'pinia';
import { TenantService } from '@/services';

export default defineStore('TenantSettingStore', {
  state: () => {
    return {
      loading: {
        settings: false,
      },
      settings: [] as string[],
    };
  },

  actions: {
    async getTenantSettings() {
      this.loading.settings = true;
      const response = await TenantService.getTenantSettings();
      this.settings = response.data.data;
      this.loading.settings = false;
    },
  },
});
