export enum EMIT {
  SAVE = 'save',
  CANCEL = 'cancel',
  CLOSE = 'close',
  BACK = 'back',
  NEXT = 'next',
  UPDATE = 'update',
  TAB = 'tab',
  REMOVE = 'remove',
  SUCCESS = 'success',
  ACCEPT = 'accept',
  UPDATE_DAY = 'updateDay',
  UPDATE_NOTE = 'updateNote',
  TOAST = 'toast',
  FILE_DROPPED = 'fileDropped',
  DOCUMENT_EVENT = 'documentEvent',
  EDIT_EVENT = 'editEvent',
  EDIT = 'edit',
  SAVE_NEW_EVENT = 'saveNewEvent',
  SAVE_EVENT_FAILED = 'saveEventFailed',
  DAY_VIEW = 'dayView',
  NEW_EVENT = 'newEvent',
  SAVE_DOCUMENT_EVENT = 'saveDocumentEvent',
  SHOULD_SAVE_GOALS = 'shouldSaveGoals',
  CLOSE_MODAL = 'closeModal',
  SHOW_MODAL = 'ShowModal',
  ADD_ITEMS = 'addItems',
  INCREMENT = 'increment',
  LOAD_MORE_ITEMS = 'loadMoreItems',
  DECREMENT = 'decrement',
  MAX = 'max',
  MOUNTED = 'mounted',
  NEW_GOAL = 'newGoal',
  ERROR = 'error',
  ENABLE = 'enable',
  DISABLE = 'disable',
  TRANSFER = 'transfer',
  ARCHIVED = 'archived',
  REMOVE_ROW = 'removeRow',
  UPDATE_GOAL_DATA = 'updateGoalData',
  UNAUTHORIZED = 'unauthorized',
  BLUR = 'blur',
  CHANGE = 'change',
  ADD = 'add',
}
