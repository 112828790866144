import { useRequestCancel } from '@/composables/useRequestCancel';
import { REQUEST } from '@/enum';
import {
  ApiPromise,
  CalendarBulkAttendanceInterface,
  DetailedEventInterface,
  FiltersTherapyEventsInterface,
  TherapyResultsInterface,
} from '@/types';
import { TherapyResultsPayloadInterface } from '@/types/Therapy';
import { HTTP } from '@/utils';

export default class CalendarService {
  static resource = 'therapy-event';
  static therapyResultsResource = 'therapy-results';
  static requestCancel = useRequestCancel();

  static getEvents(filterEvents: FiltersTherapyEventsInterface): ApiPromise {
    const eventResource =
      process.env.NODE_ENV === 'development'
        ? `therapy-event?limit=${process.env.VUE_APP_THERAPY_EVENT_LIMIT || 1000}`
        : this.resource;

    return HTTP.get(`${eventResource}`, {
      params: filterEvents,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.EVENTS),
    });
  }

  static getEventsByOrganization(start: string, end: string, organizationIds: Array<number>): ApiPromise {
    const query = `?start_at=${start}&end_at=${end}`;

    return HTTP.get(`${this.resource}${query}`, {
      params: {
        organizations: organizationIds,
      },
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.EVENT_BY_ORGANIZATION),
    });
  }

  static getEventByAttendee(attendee_id: number) {
    return HTTP.get(`${this.resource}/attendee_id=${attendee_id}`);
  }

  static getEvent(id: string) {
    return HTTP.get(`${this.resource}/${id}`);
  }

  static updateEvent(event: DetailedEventInterface) {
    return HTTP.patch(`${this.resource}/${event.id}`, event);
  }

  static updateTherapyResult(payload: TherapyResultsInterface[]) {
    return HTTP.post(`${this.therapyResultsResource}`, { therapy_results: payload });
  }

  static storeUpdateTherapyResults(payload: TherapyResultsPayloadInterface, eventProcessType?: string) {
    return HTTP.post(`${this.therapyResultsResource}`, {
      event_id: payload.event_id,
      event_date: payload.event_date,
      therapy_results: payload.therapy_results,
      updated: payload.updated,
      event_process_type: eventProcessType,
    });
  }

  static getTherapyResultTypes() {
    return HTTP.get('therapy-result-types');
  }

  static bulkAttendance(payload: CalendarBulkAttendanceInterface) {
    return HTTP.post(`${this.therapyResultsResource}/bulk-update`, payload);
  }

  static getPastDocuments(eventId: number, eventDate: string, attendee_id: number, last_date: string) {
    const baseUrl = `${this.resource}/${eventId}/results?event_date=${eventDate}`;
    let url = attendee_id ? baseUrl + `&attendee_id=${attendee_id}` : baseUrl;
    url = url + `&last_date=${last_date}`;

    return HTTP.get(url);
  }

  static getOverlappingEvents(filterEvents: FiltersTherapyEventsInterface): ApiPromise {
    return HTTP.get(`${this.resource}/overlapping`, {
      params: filterEvents,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.OVERLAPPING_EVENTS),
    });
  }
}
