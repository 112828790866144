/* eslint-disable */
// @ts-nocheck

import { defineStore } from 'pinia';
import {
  ReferralCaseloadsFilterInterface,
  ReferralCaseloadsInterface,
  StudentReferralStateInterface,
  UserReferralListFilterInterface,
  StudentReferralInterface,
  ReferralPayloadInterface,
  ClinicianStudentInterface,
  ReferralProcessInterface,
  ReferralRecordInterface,
  ClinicianDropdownInterface,
} from '@/types';
import ReferralService from '@/services/ReferralService';
import { PersonService } from '@/services';
import { StudentOrganizationInterface } from '@/types/Student';
import { ALL_FREQUENCY_TYPES, FREQUENCY_INTERVALS, REFERRAL_TYPES } from '@/enum';
import { useIsEqual, useArrayUtilities } from '@/composables';
import { UserTreatmentPlanStore, useServicesStore, useDisciplineStore, useClinicianOrganizationStore } from '@/store';

export default defineStore('StudentReferralStore', {
  state: (): StudentReferralStateInterface => ({
    message: undefined,
    error: false,
    loading: false,
    studentsLoading: false,
    serviceProviders: [],
    organizations: [],
    referralOptionsloading: false,
    referralTypes: [],
    referralUrgencies: [],
    approving: false,
    processes: [],
    intervalTypes: [],
    categories: [],
    serviceTypes: [],
    gradeOptions: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    genderOptions: [
      { value: 'M', name: 'Male' },
      { value: 'F', name: 'Female' },
    ],
    organizationsLoading: false,
    students: {
      data: [] as StudentReferralInterface[],
      total: 0,
    },
    approvedStudentIds: [],
    referrals_caseload: {
      data: [] as ReferralCaseloadsInterface[],
      total: 0,
    },
    mTSSReferralTableData: [] as Record<string, string>[],
    initialEvalReferralTableData: [],
    reassignmentReferralTableData: [],
    transferReferralTableData: [],
    bulkFileColumns: [
      'referral_type',
      'student_id',
      'student_first_name',
      'student_last_name',
      'zipcode',
      'building',
      'referral_reason',
      'referral_urgency',
      'gender',
      'birthdate',
      'grade',
      'phone',
      'treatment_plan_type',
      'service_category',
      'service',
      'service_line',
      'service_type',
      'evaluation_due_date',
      'service_start_date',
      'service_end_date',
      'service_duration',
      'service_interval',
      'service_frequency',
      'parent_first_name',
      'parent_last_name',
      'parent_email',
      'service_provider',
    ],
  }),

  getters: {
    getStudentOrganizationServiceProviders: (state) => (orgId: number) => {
      let clinicians: ClinicianStudentInterface[] = [];
      const organizations = state.organizations?.filter((org: StudentOrganizationInterface) => org.id === orgId);

      organizations?.forEach((organization) => {
        if (organization.clinicians.length) clinicians = [...organization.clinicians];

        if (organization.ancestors.length) {
          organization.ancestors.forEach((ancestor) => {
            if (ancestor.clinicians.length) clinicians = [...ancestor.clinicians];
          });
        }
      });

      return useArrayUtilities().useValueNameSort(clinicians);
    },
  },

  actions: {
    catchError(e: Error) {
      console.error(e);
      this.loading = false;
      this.error = true;
      this.message = e.message ? e.message : 'Something went wrong';
      this.doToast(this.message, 'Error', this.error);
      throw e;
    },

    loaded() {
      this.loading = false;
      this.error = false;
      this.message = undefined;
    },

    newRow(referralType: string) {
      let row;

      const mtss = {
        reason: { label: 'Reason for Referral', value: null, required: true },
        urgency: { label: 'Urgency', value: null, required: false },
      };

      const initial = {
        disciplines: { label: 'Service', value: '', required: true },
        due_date: { label: 'Due Date', value: null, required: true },
      };

      const reassignmentTransfer = {
        treatment_plan_type: { label: 'Treatment Plan Type', value: '', required: true },
        service_category: { label: 'Service Category', value: '', required: true },
        disciplines: { label: 'Service', value: '', required: false },
        service_line: { label: 'Service Line', value: '', required: false },
        service_type: { label: 'Service Type', value: '', required: false },
        service_start: { label: 'Service Start Date', value: '', required: false },
        service_end: { label: 'Service End Date', value: '', required: false },
        duration: { label: 'Duration', value: 30, required: false },
        frequency: { label: 'Frequency', value: '', required: false },
        interval: { label: 'Interval', value: '', required: false },
      };

      const baseRow = {
        row_status: { disable: false, processing: false, completed: false },
        errors: null,
        referral_type: { label: 'Referral Type', value: 0, required: true },
        id: { label: 'Student ID', value: '', required: true },
        last_name: { label: 'Student Last Name', value: '', required: true },
        first_name: { label: 'Student First Name', value: '', required: true },
        zip: { label: 'Zip Code', value: '', required: true },
        location: { label: 'Building/Campus', value: '', required: false },
        gender: { label: 'Gender', value: '', required: false },
        birthdate: { label: 'Birthdate', value: '', required: false },
        grade: { label: 'Grade', value: '', required: false },
        phone: { label: 'Phone', value: '', required: false },
        parent_first_name: { label: 'Parent First Name', value: '', required: false },
        parent_last_name: { label: 'Parent Last Name', value: '', required: false },
        parent_email: { label: 'Parent Email', value: '', required: false },
        service_provider: { label: 'Service Provider', value: '', required: false },
      };

      switch (referralType) {
        case REFERRAL_TYPES.MTSS:
          row = { ...baseRow, ...mtss };
          break;

        case REFERRAL_TYPES.INITIAL:
          row = { ...baseRow, ...initial };
          break;

        case REFERRAL_TYPES.REASSIGNMENT:
        case REFERRAL_TYPES.TRANSFER:
          row = { ...baseRow, ...reassignmentTransfer };
          break;
      }

      return structuredClone(row);
    },

    async getReferralCaseload(filters?: ReferralCaseloadsFilterInterface) {
      this.loading = true;
      await ReferralService.getReferralCaseload(filters)
        .then((response) => {
          this.referrals_caseload.data = response.data.data;
          this.referrals_caseload.total = response.data.data.length;
          this.loaded();
        })
        .catch(this.catchError);
    },

    getFilteredOrganizationServiceProviders(ids: number[]) {
      const idLength = ids.length;
      const allProviders: ClinicianDropdownInterface[] = [];

      ids.forEach((id) => {
        const referral = this.students.data.find((student) => student.id === id);
        if (referral) {
          referral.organization_service_providers.forEach((sp) => {
            const found = allProviders.find((clinician) => clinician.id === sp.id);
            if (found && found.count) found.count++;
            else {
              const clin: ClinicianDropdownInterface = { count: 1, ...sp };
              allProviders.push(clin);
            }
          });
        }
      });
      return allProviders.filter((provider) => provider.count === idLength);
    },

    treatmentPlanCategoriesByRowTreatmentPlanType(id: number) {
      return this.categories?.filter((category) => {
        return category.treatment_plan_type_id === id;
      });
    },

    getCliniciansAvailableForReferral(ids: number[]) {
      this.serviceProviders = this.getFilteredOrganizationServiceProviders(ids);
    },

    async getOrganizations() {
      this.loading = true;
      await ReferralService.getReferralOrganizations()
        .then((response) => {
          this.organizations = response.data.data;
          this.loaded();
        })
        .catch(this.catchError);
    },

    async getStudents(filters?: UserReferralListFilterInterface) {
      this.studentsLoading = true;
      await ReferralService.getReferralAssign(filters)
        .then((response) => {
          this.students.data = response.data.data;
          this.students.total = response.data.meta.total;
          this.studentsLoading = false;
          this.error = false;
        })
        .catch(this.catchError);
    },

    assignErrorToTable(errors: string, table: string, key: number) {
      switch (table) {
        case REFERRAL_TYPES.MTSS:
          this.mTSSReferralTableData[key].errors = errors;
          break;
        case REFERRAL_TYPES.INITIAL:
          this.initialEvalReferralTableData[key].errors = errors;
          break;
        case REFERRAL_TYPES.REASSIGNMENT:
          this.reassignmentReferralTableData[key].errors = errors;
          break;
        case REFERRAL_TYPES.TRANSFER:
          this.transferReferralTableData[key].errors = errors;
          break;
      }
    },
    parseCustomReferralTypes(payload: ReferralProcessInterface) {
      const type = this.intervalTypes?.find((type) => type.title.toLowerCase() === payload.treatment_service_interval);

      switch (type?.acronym) {
        case ALL_FREQUENCY_TYPES.WEEK2:
          payload.treatment_service_interval = FREQUENCY_INTERVALS.WEEKLY;
          payload.treatment_service_frequency = 2;
          break;
        case ALL_FREQUENCY_TYPES.MONTH2:
          payload.treatment_service_interval = FREQUENCY_INTERVALS.MONTHLY;
          payload.treatment_service_frequency = 2;
          break;
        case ALL_FREQUENCY_TYPES.WEEK3:
          payload.treatment_service_interval = FREQUENCY_INTERVALS.WEEKLY;
          payload.treatment_service_frequency = 3;
          break;
        case ALL_FREQUENCY_TYPES.WEEKLY.toUpperCase():
          payload.treatment_service_interval = FREQUENCY_INTERVALS.WEEKLY;
          payload.treatment_service_frequency = 1;
          break;
        case ALL_FREQUENCY_TYPES.MONTHLY.toUpperCase():
          payload.treatment_service_interval = FREQUENCY_INTERVALS.MONTHLY;
          payload.treatment_service_frequency = 1;
          break;
        case ALL_FREQUENCY_TYPES.SEMESTERLY.toUpperCase():
          payload.treatment_service_interval = FREQUENCY_INTERVALS.SEMESTERLY;
          payload.treatment_service_frequency = 1;
          break;
        case ALL_FREQUENCY_TYPES.QUARTERLY.toUpperCase():
          payload.treatment_service_interval = FREQUENCY_INTERVALS.QUARTERLY;
          payload.treatment_service_frequency = 1;
          break;
        case ALL_FREQUENCY_TYPES.YEARLY.toUpperCase():
          payload.treatment_service_interval = FREQUENCY_INTERVALS.YEARLY;
          payload.treatment_service_frequency = 1;
          break;
      }
      return payload;
    },
    async process(payload: ReferralProcessInterface, key: number, table: string) {
      this.loading = true;

      const response = await ReferralService.process(payload).catch((error) => {
        this.error = true;
        if (error.status === 422) {
          const errorString = Object.values(error.data.errors).join(' ');
          this.assignErrorToTable(errorString, table, key);
        } else if (error.status === 500) {
          const errorString = error.message ? error.message : 'Something went wrong';
          this.assignErrorToTable(errorString, table, key);
        } else {
          this.catchError(error);
        }
      });

      if (response) {
        this.processes = response.data.data;
      }

      this.loading = false;
    },

    async loadOptions() {
      this.referralOptionsloading = true;
      const treatmentPlansStore = UserTreatmentPlanStore();

      await ReferralService.getReferrralOptions()
        .then((response) => {
          if (response.data.data) {
            this.referralTypes = response.data.data.referral_types;
            this.referralReasons = response.data.data.referral_reasons;
            this.referralUrgencies = response.data.data.referral_urgencies;
          }

          this.referralOptionsloading = false;
        })
        .catch(this.catchError);

      this.intervalTypes = await treatmentPlansStore.getTreatmentPlanFrequencyTypes();
      this.serviceTypes = await treatmentPlansStore.getTreatmentServiceTypes();
      await treatmentPlansStore.getTreatmentPlanTypes();
      const response = await PersonService.getAllTreatmentPlanCategories().catch(this.catchError);
      if (response.data.data) this.categories = response.data.data;
    },

    async approveReferrals(payload: ReferralPayloadInterface) {
      await ReferralService.approveReferrals(payload)
        .then((response) => {
          if (!this.error && response.data.data.id) {
            this.approvedStudentIds?.push(payload.id);
          }
          this.error = false;
        })
        .catch(this.catchError);
    },

    getReferralTypeId(referralType: string) {
      if (this.referralTypes) {
        return this.referralTypes?.find((type: { title: string }) => type.title === referralType)?.id;
      }

      return null;
    },

    onReferralTypeChange(data: ReferralRecordInterface, fromReferralType: string) {
      this.onReferralTypeChangeRemove(data, fromReferralType);
      this.onReferralTypeChangeAdd(data);
    },

    onReferralTypeChangeRemove(data: ReferralRecordInterface, fromReferralType: string) {
      // eslint-disable-next-line
      // @ts-ignore
      const removeItem = (object) => {
        const referral = this.newRow(fromReferralType);

        if (referral) {
          referral.referral_type.value = this.getReferralTypeId(fromReferralType) as number;
          // eslint-disable-next-line
          // @ts-ignore
          referral.key = 0 as string;
        }

        object.splice(data.key, 1);

        const clone = [...object];
        const mtss = clone.filter((item) => item.key.toString() !== data.key.toString());
        object = mtss.map((item, index: number) => {
          item.key = index;
          return item;
        });

        if (!object.length) {
          object.push(referral);
        }
      };

      switch (fromReferralType) {
        case REFERRAL_TYPES.MTSS: {
          removeItem(this.mTSSReferralTableData);
          break;
        }
        case REFERRAL_TYPES.INITIAL: {
          removeItem(this.initialEvalReferralTableData);
          break;
        }
        case REFERRAL_TYPES.REASSIGNMENT: {
          removeItem(this.reassignmentReferralTableData);
          break;
        }
        case REFERRAL_TYPES.TRANSFER: {
          removeItem(this.transferReferralTableData);
          break;
        }
      }
    },

    onReferralTypeChangeAdd(data: ReferralRecordInterface) {
      const toReferralTypeItem = this.referralTypes?.find(
        (type: { id: number }) => type.id === data.referral_type.value,
      );

      if (toReferralTypeItem) {
        const addItem = (object) => {
          const referral = this.newRow(toReferralTypeItem.title);
          const baseData = {
            referral_type: data.referral_type,
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            zip: data.zip,
            location: data.location,
            gender: data.gender,
            birthdate: data.birthdate,
            grade: data.grade,
            phone: data.phone,
            parent_first_name: data.parent_first_name,
            parent_last_name: data.parent_last_name,
            parent_email: data.parent_email,
            service_provider: data.service_provider,
          };

          const payload: Record<string, string> = { ...referral, ...baseData };

          payload.key = object.length + 1;
          object.unshift(payload);

          object = object.map((item, key) => {
            item.key = key;
            return item;
          });
        };

        switch (toReferralTypeItem.title) {
          case REFERRAL_TYPES.MTSS: {
            addItem(this.mTSSReferralTableData);
            break;
          }
          case REFERRAL_TYPES.INITIAL: {
            addItem(this.initialEvalReferralTableData);
            break;
          }
          case REFERRAL_TYPES.REASSIGNMENT: {
            addItem(this.reassignmentReferralTableData);
            break;
          }
          case REFERRAL_TYPES.TRANSFER: {
            addItem(this.transferReferralTableData);
            break;
          }
        }
      }
    },

    onBulkFileImport(file: Record<string, string>[]) {
      const validate = this.validateBulkFileColumns(file);

      if (validate) {
        this.processBulkFileContent(file);
      } else {
        this.doToast(
          'Could not bulk import referrals as columns within the file are not correct. Please refer to the doanloadable template within this window.',
          'Error',
          true,
        );
      }
    },

    validateBulkFileColumns(file: Record<string, string>[]) {
      if (file.length > 0) {
        const fileColumns = Object.keys(file[0]);

        return useIsEqual(this.bulkFileColumns, fileColumns);
      }

      return false;
    },

    processBulkFileContent(file: Record<string, string>[]) {
      file.forEach((item: Record<string, string>) => {
        let referral = this.defineBulkBaseData(item);

        if (referral) {
          // @ts-expect-error - Added to correct random type issue.
          referral = this.defineBulkInitialEvaluationData(referral, item);
          // @ts-expect-error - Added to correct random type issue.
          referral = this.defineBulkTransferAndReassignmentData(referral, item);

          switch (item.referral_type) {
            case REFERRAL_TYPES.MTSS: {
              // @ts-expect-error - Added to correct random type issue.
              referral.key = this.mTSSReferralTableData.length + 1;
              // @ts-expect-error - Added to correct random type issue.
              this.mTSSReferralTableData.push(referral);
              break;
            }
            case REFERRAL_TYPES.INITIAL: {
              // @ts-expect-error - Added to correct random type issue.
              referral.key = this.initialEvalReferralTableData.length + 1;
              // @ts-expect-error - Added to correct random type issue.
              this.initialEvalReferralTableData.push(referral);
              break;
            }
            case REFERRAL_TYPES.REASSIGNMENT: {
              // @ts-expect-error - Added to correct random type issue.
              referral.key = this.reassignmentReferralTableData.length + 1;
              // @ts-expect-error - Added to correct random type issue.
              this.reassignmentReferralTableData.push(referral);
              break;
            }
            case REFERRAL_TYPES.TRANSFER: {
              // @ts-expect-error - Added to correct random type issue.
              referral.key = this.transferReferralTableData.length + 1;
              // @ts-expect-error - Added to correct random type issue.
              this.transferReferralTableData.push(referral);
              break;
            }
          }
        }
      });
    },

    defineBulkBaseData(item: Record<string, string>) {
      const clinicianStore = useClinicianOrganizationStore();
      const referral = this.newRow(item.referral_type);

      if (referral) {
        if (referral.referral_type && item.referral_type.length) {
          // eslint-disable-next-line
          // @ts-ignore
          referral.referral_type.value = this.getReferralTypeId(item.referral_type) as number;
        }

        if ('reason' in referral && item.referral_reason.length) {
          // eslint-disable-next-line
          // @ts-ignore
          referral.reason.value = this.getReferralReasonId(item.referral_reason);
        }

        if ('urgency' in referral && item.referral_urgency) {
          // eslint-disable-next-line
          // @ts-ignore
          referral.urgency.value = this.getReferralUrgencyId(item.referral_urgency);
        }

        referral.id.value = item.student_id;
        referral.first_name.value = item.student_first_name;
        referral.last_name.value = item.student_last_name;
        referral.zip.value = item.zipcode;
        referral.location.value = item.building;
        referral.gender.value = item.gender;
        referral.birthdate.value = item.birthdate;
        referral.grade.value = item.grade;
        referral.phone.value = item.phone;
        referral.parent_first_name.value = item.parent_first_name;
        referral.parent_last_name.value = item.parent_last_name;
        referral.parent_email.value = item.parent_email;

        const clinicianId = clinicianStore.clinicians.find(
          (clinician) => clinician.display_name === item.service_provider,
        );

        // @ts-expect-error - Added to correct random type issue.
        referral.service_provider.value = clinicianId?.id;
      }

      return referral;
    },

    defineBulkInitialEvaluationData(
      referral: Record<string, string>,
      item: Record<string, string>,
    ): Record<string, string> {
      if (item.referral_type === REFERRAL_TYPES.INITIAL) {
        const disciplineStore = useDisciplineStore();
        const discipline = disciplineStore.disciplines?.find((discipline) => discipline.name === item.service);

        // @ts-expect-error - Added to correct random type issue.
        referral.disciplines.value = discipline ? discipline?.id : null;

        if (item.evaluation_due_date.length) {
          // @ts-expect-error - Added to correct random type issue.
          referral.due_date.value = new Date(item.evaluation_due_date);
        }
      }

      return referral;
    },

    defineBulkTransferAndReassignmentData(
      referral: Record<string, string>,
      item: Record<string, string>,
    ): Record<string, string> {
      if (item.referral_type === REFERRAL_TYPES.REASSIGNMENT || item.referral_type === REFERRAL_TYPES.TRANSFER) {
        const treatmentPlansStore = UserTreatmentPlanStore();
        const servicesStore = useServicesStore();
        const disciplineStore = useDisciplineStore();

        const treatmentPlanType = treatmentPlansStore.treatmentPlanTypes?.find(
          // @ts-expect-error - Added to correct random type issue.
          (treatmentPlanType) => treatmentPlanType.name === item.treatment_plan_type,
        );
        const treatmentPlanCategory = this.categories?.find(
          (treatmentPlanCategory) => treatmentPlanCategory.name === item.service_category,
        );
        const treatmentPlanServiceType = this.serviceTypes?.find(
          (treatmentPlanServiceType) => treatmentPlanServiceType.acronym === item.service_type,
        );
        const services = servicesStore.services?.find((service) => service.name === item.service_line);
        const discipline = disciplineStore.disciplines?.find((discipline) => discipline.name === item.service);

        // @ts-expect-error - Added to correct random type issue.
        referral.treatment_plan_type.value = treatmentPlanType?.id;
        // @ts-expect-error - Added to correct random type issue.
        referral.service_category.value = treatmentPlanCategory?.id;
        // @ts-expect-error - Added to correct random type issue.
        referral.service_type.value = treatmentPlanServiceType?.id;
        // @ts-expect-error - Added to correct random type issue.
        referral.disciplines.value = discipline?.id;
        // @ts-expect-error - Added to correct random type issue.
        referral.service_line.value = services?.id;
        // @ts-expect-error - Added to correct random type issue.
        referral.service_start.value = new Date(item.service_start_date);
        // @ts-expect-error - Added to correct random type issue.
        referral.service_end.value = new Date(item.service_end_date);
        // @ts-expect-error - Added to correct random type issue.
        referral.duration.value = Number(item.service_duration);
        // @ts-expect-error - Added to correct random type issue.
        referral.interval.value = item.service_interval;
        // @ts-expect-error - Added to correct random type issue.
        referral.frequency.value = Number(item.service_frequency);
      }

      return referral;
    },

    getReferralReasonId(reason: string) {
      if (this.referralReasons) {
        return this.referralReasons?.find((item: { title: string }) => item.title === reason)?.id;
      }

      return null;
    },

    getReferralUrgencyId(urgency: string) {
      if (this.referralUrgencies) {
        return this.referralUrgencies?.find((item: { title: string }) => item.title === urgency)?.id;
      }

      return null;
    },
  },
});
