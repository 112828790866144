import { AES } from 'crypto-ts';
import { defineStore } from 'pinia';
import { VueCookieNext } from 'vue-cookie-next';
import { RouteLocationRaw } from 'vue-router';
import { useAuthStore, usePeopleTypesStore, useOrganizationStore, useTenantSettingStore } from '@/store';
import { can, setPermissions } from '@/composables';
import { PERMISSIONS } from '@/enum';
import router from '@/router';
import { AuthService, ImpersonateService, ProfileService, UserService } from '@/services';
import {
  AuthStateInterface,
  ForgotPasswordInterface,
  LoginInterface,
  OrganizationsParentsInterface,
  PermissionInterface,
  ResetPasswordInterface,
  RoleInterface,
  TenantInterface,
  TokenInterface,
  UserInterface,
} from '@/types';

export const UserModel: UserInterface = {
  firstName: '',
  lastName: '',
  timezone: '',
  email: '',
  type: '',
  id: '',
};

export const LoginModel: LoginInterface = { email: undefined, password: undefined };

export const ResetModel: ResetPasswordInterface = { password: '', password_confirmation: '', token: '' };

export default defineStore('AuthStore', {
  state: (): AuthStateInterface => ({
    organizationExternalId: undefined,
    organizationId: undefined,
    organizationName: undefined,
    organizationAssociated: [] as OrganizationsParentsInterface[],
    loggedIn: false,
    token: undefined,
    impersonateToken: undefined,
    loginModel: LoginModel,
    redirectURL: undefined,
    resetModel: ResetModel,
    error: undefined,
    success: undefined,
    warning: undefined,
    loading: false,
    loginLoading: false,
    user: undefined,
    tokenResetExpired: undefined,
    tenants:
      {
        guest: ({} as TenantInterface) || null,
        host: {} as TenantInterface,
      } || ({} as TenantInterface),
    impersonateTenantToken: undefined,
    userRole: [] as RoleInterface[],
    permissions: [] as PermissionInterface[],
    wasInactive: false,
  }),

  getters: {
    acronymType: (state) => state.user?.type?.acronym,
    isAuthenticated: (state: AuthStateInterface) => !!state.token,
    loginObject: (state: AuthStateInterface) => state.loginModel,
    accessToken: (state: AuthStateInterface) => state.token,
    personType: (state) => state.user?.type,
    isImpersonating: (state) => !!state.impersonateToken,
    isSwitchTenant: (state) => !!state.impersonateTenantToken,
    displayName: (state) => `${state.user?.first_name} ${state.user?.last_name}`,
    typeTitle: (state) => state.user?.type?.title,
    masterTenantId: (state) => state.token?.tenant?.master,
    guestTenantIsMaster: (state) => state.impersonateTenantToken?.guest_tenant?.master,
    currentInstanceIsMasterTenant(): boolean {
      if (this.isSwitchTenant) {
        return !!this.guestTenantIsMaster;
      }
      return !!this.masterTenantId;
    },
    permissionGlobal: (state) => {
      setPermissions(state.permissions);
      return state.permissions;
    },
  },

  actions: {
    /**
     * Execute any necessary requests for the application to be functional.
     */
    async boot() {
      if (this.loggedIn) {
        this.loading = true;
        await this.getPermissions();
        await useTenantSettingStore().getTenantSettings();
        await usePeopleTypesStore().getPeopleTypes();
        await this.getOrganization();
        await this.getOrganizations();
        this.loading = false;
      }
    },

    async getUserRole() {
      const { data } = await UserService.getUserRole();
      this.userRole = data.data.roles;
      if (this.userRole.length > 0) {
        const rolesName = `${this.userRole.map((role) => role.name).join(',')}`;
        const rolesEncrypted = AES.encrypt(rolesName, this.accessToken?.access_token || 'Eluma').toString();
        localStorage.setItem('roles', rolesEncrypted);
      }
    },

    async getPermissions() {
      await UserService.getPermissions()
        .then((response) => {
          const { data } = response;
          this.permissions = data.data;
          if (this.permissions.length > 0) {
            const permissionsArray = this.permissions.map((permission) => permission.name);
            const permissionsNamesEncrypted = AES.encrypt(
              JSON.stringify({ permissionsArray }),
              this.accessToken?.access_token ?? 'Eluma',
            ).toString();
            localStorage.setItem('permissions', permissionsNamesEncrypted);
            this.permissionGlobal;
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            this.loading = false;
            this.error = 'Insufficient permissions to show user permissions';
            console.error(this.error);
            this.logout();
          }
        });
    },
    isAdmin() {
      const PEOPLE_TYPES = usePeopleTypesStore().PEOPLE_TYPES;
      return !!(
        this.user?.type?.acronym === PEOPLE_TYPES.SUPPORT_ADMIN || this.user?.type?.acronym === PEOPLE_TYPES.SUPER_ADMIN
      );
    },
    /**
     * if not associated with an org but they are support admin assign them to eLuma org
     */
    async setOrganizationIfAdmin() {
      if (this.currentInstanceIsMasterTenant && this.isAdmin()) {
        const orgByName = await useOrganizationStore().getOrganizationByName('eLuma');
        if (orgByName) {
          this.organizationId = orgByName.id;
          this.organizationName = orgByName.name;
        }
      }
    },
    async getOrganization() {
      await UserService.getOrganization()
        .then((response) => {
          const { data } = response;

          if (data.data) {
            this.organizationId = data.data.id;
            this.organizationName = data.data.name;
            this.organizationExternalId = data.data.external_id;
          } else {
            this.setOrganizationIfAdmin();
          }
        })
        .catch((error) => {
          if (!can([PERMISSIONS.USERS_ORGANIZATION_SHOW] || error.status === 403)) {
            this.loading = false;
            this.error = 'Insufficient permissions to show user organization';
            console.error(this.error);
            this.organizationId = undefined;
            this.organizationName = undefined;
            this.organizationExternalId = undefined;
            return;
          }
        });
    },

    async getOrganizations(listFormat?: boolean) {
      if (!can([PERMISSIONS.USERS_ORGANIZATIONS_SHOW])) {
        this.loading = false;
        this.error = 'Insufficient permissions to show user organizations';
        console.error(this.error);
        return [];
      }

      const response = await UserService.getOrganizations(listFormat);
      this.organizationAssociated = response.data.data;
      return this.organizationAssociated;
    },

    async getAuthUserProfile() {
      const { data } = await ProfileService.getProfile().catch(this.catchError);
      this.user = data.data;
      this.tenants = data.data.tenants;
      this.getPermissions();
      return this.user;
    },

    /**
     * login
     *
     * call auth service, set token and logged in state
     * redirect to dashboard
     *
     * if there is a token then set it in cookie and state
     *  if not its not valid and will be caught in the HTTP root
     */
    async login() {
      this.loginLoading = true;
      await AuthService.login(this.loginModel)
        .then(async (response) => {
          this.loginLoading = false;
          const currentTenant = response.data.tenant;
          if (response.data) {
            this.setCookie('accessToken', response.data, response.data.expires_in);
            this.setCookie('tenant', currentTenant, response.data.expires_in);
            this.token = response.data;
            this.loggedIn = true;
            this.error = undefined;

            await this.getAuthUserProfile();
            await usePeopleTypesStore().getPeopleTypes();
            //redirect to the Dashboard or Tenant Admin based on person type
            if (
              useAuthStore().personType?.acronym === usePeopleTypesStore().PEOPLE_TYPES.SUPPORT_ADMIN ||
              useAuthStore().personType?.acronym === usePeopleTypesStore().PEOPLE_TYPES.SUPER_ADMIN
            ) {
              useAuthStore().redirectURL ? this.handleRedirect() : router.push('/admin');
            } else if (useAuthStore().redirectURL) this.handleRedirect();
            else await router.push('/');

            // Get the organization id that the user is associated to and save it to state.
            await this.boot();
          }
        })
        .catch((e) => {
          this.loginLoading = false;
          this.error = e.data.message;
        });
    },
    async handleRedirect() {
      const redirect = useAuthStore().redirectURL;
      useAuthStore().redirectURL = undefined;
      await router.push(redirect as RouteLocationRaw);
    },
    /* Setting a cookie. */
    // eslint-disable-next-line
    setCookie(cookieName: string, data: any, expire: number) {
      VueCookieNext.setCookie(cookieName, data, {
        expire: expire,
        path: '/',
        domain: '',
        secure: 'true',
        sameSite: '',
      });
    },

    async forgotPassword(payload: ForgotPasswordInterface) {
      return AuthService.forgotPassword(payload);
    },

    /**
     * logout
     *
     * LOGOUT of the app. remove token and all user data
     */
    async logout() {
      await AuthService.logout().catch(this.catchError);

      VueCookieNext.removeCookie('accessToken');
      VueCookieNext.removeCookie('tenant');
      localStorage.removeItem('permissions');

      if (VueCookieNext.getCookie('impersonateAccessToken')) VueCookieNext.removeCookie('impersonateAccessToken');
      if (VueCookieNext.getCookie('access_token_tenant')) VueCookieNext.removeCookie('access_token_tenant');

      document.body.classList.remove('theme-admin');
      this.resetAuthStore();
      router.push('/account/login');
    },

    /**
     * Reset Password
     *
     * Send request to reset password  of the app.
     *
     * * @param commit
     */
    async resetPassword() {
      await AuthService.resetPassword(this.resetModel)
        // eslint-disable-next-line no-unused-vars
        .then(() => {
          this.success = 'You password has been reset successfully.';
          setTimeout(() => {
            router.push(this.loggedIn ? '/profile' : '/account/login');
          }, 2000);
        })
        .catch((response) => {
          if (response.status === 422) {
            this.tokenResetExpired = response.data.message;
            router.push('/account/forgot-password');
          }
        });
    },

    /**
     * stop the login and throw an error
     *
     * @param payload
     */
    async stopLogin() {
      this.loggedIn = false;

      if (this.token !== undefined) {
        await this.logout();
        this.wasInactive = true;
      } else {
        this.error = undefined;
        this.token = undefined;
      }
    },

    /**
     * fetch the access token on app refresh
     */
    async fetchAccessToken() {
      const token = await VueCookieNext.getCookie('accessToken');
      const impersonateToken = await VueCookieNext.getCookie('impersonateAccessToken');
      const tenant = await VueCookieNext.getCookie('tenant');
      const impersonateTenantToken = await VueCookieNext.getCookie('access_token_tenant');
      if (token) {
        this.loggedIn = true;
        this.error = undefined;
        this.token = token;
        if (this.token) {
          this.token.tenant = tenant;
        }
        this.tenants = tenant;
      }

      if (impersonateToken) {
        this.impersonateToken = impersonateToken;
      }

      if (impersonateTenantToken) {
        this.impersonateTenantToken = impersonateTenantToken;
      }
    },

    async setTmsBackpackCookie() {
      await this.fetchAccessToken();

      if (this.token) {
        const domain = window.location.hostname.split(/\./).slice(-2).join('.');
        VueCookieNext.setCookie('tms_access_token', this.token.access_token, { domain });
      }
    },

    /**
     * catch errors
     * @param e
     */
    catchError(e: Error) {
      if (this.error === undefined) {
        this.error = 'error';
      }
      throw e;
    },

    async impersonate(impersonateId: number) {
      await ImpersonateService.impersonate(impersonateId)
        .then(async (result) => {
          VueCookieNext.setCookie('impersonateAccessToken', result.data, {
            expire: result.data.expires_in,
            path: '/',
            domain: '',
            secure: 'true',
            sameSite: '',
          });

          this.impersonateToken = result.data;
          await this.getAuthUserProfile();
          window.location.href = '/';
        })
        .catch((e) => {
          this.error = e.data.message;
        });
    },

    async leaveImpersonate(isImpersonatingWhenSwitchingTenant?: boolean) {
      this.loading = true;
      await ImpersonateService.leaveImpersonate().catch((e) => {
        this.error = e.data.message;
      });

      this.clearImpersonateData();

      if (!isImpersonatingWhenSwitchingTenant) {
        await this.getAuthUserProfile();
        this.loading = false;
        if (router.currentRoute.value.path === '/dashboard/overview') {
          router.go(0);
        } else {
          window.location.href = '/';
        }
      }
    },

    async getConnections() {
      const response = await AuthService.getConnections();
      return response.data;
    },

    clearImpersonateData() {
      VueCookieNext.removeCookie('impersonateAccessToken');
      this.error = undefined;
      this.impersonateToken = undefined;
    },

    async setTenantToSwitch(access_tenant_token: TokenInterface) {
      this.loading = true;
      this.impersonateTenantToken = { ...access_tenant_token };
      const { guest_tenant, access_token } = this.impersonateTenantToken;

      if (!guest_tenant) {
        this.impersonateTenantToken.guest_tenant = this.token?.tenant;
        this.impersonateTenantToken = { ...this.impersonateTenantToken, access_tenant_token: access_token };
      }

      if (this.impersonateTenantToken?.guest_tenant) {
        await this.setCookie('access_token_tenant', this.impersonateTenantToken, access_tenant_token.expires_in);
        await router.push('/');

        document.body.classList.remove('theme-admin');
        document.body.classList.add('theme');
        await this.getAuthUserProfile();
      } else {
        this.loading = true;
      }
      this.loading = false;
    },

    resetAuthStore() {
      this.organizationId = undefined;
      this.organizationName = undefined;
      this.organizationExternalId = undefined;
      this.organizationAssociated = [] as OrganizationsParentsInterface[];
      this.loggedIn = false;
      this.token = undefined;
      this.impersonateToken = undefined;
      this.loginModel = { email: undefined, password: undefined } as LoginInterface;
      this.resetModel = ResetModel;
      this.error = undefined;
      this.success = undefined;
      this.warning = undefined;
      this.loading = false;
      this.user = undefined;
      this.tokenResetExpired = undefined;
      this.redirectURL = undefined;
      this.tenants = {
        host: {} as TenantInterface,
        guest: null,
      };
      this.impersonateTenantToken = undefined;
      this.userRole = [] as RoleInterface[];
      this.permissions = [] as PermissionInterface[];
      this.wasInactive = false;
    },
  },
});
