import { ApiPromise } from '@/types';

import { HTTP } from '@/utils';

export default class TenantService {
  static resource = 'tenant';

  static getTenantSettings(): ApiPromise {
    return HTTP.get(`${this.resource}/settings`);
  }
}
