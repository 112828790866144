import { LAYOUTS, PERMISSIONS } from '@/enum';

const Quickbooks = () =>
  import(/* webpackChunkName: "tenant.quickbooks" */ '@/views/Admin/Tenants/components/Quickbooks');
const QuickbooksRedirect = () =>
  import(
    /* webpackChunkName: "tenant.quickbooks.redirect" */ '@/views/Admin/Tenants/components/Quickbooks/QuickbooksRedirect.vue'
  );
const Tenants = () => import(/* webpackChunkName: "tenants" */ '@/views/Admin/Tenants');
const TenantList = () => import(/* webpackChunkName: "tenant_list" */ '@/views/Admin/Tenants/components/TenantList');
const Tenant = () => import(/* webpackChunkName: "tenant" */ '@/views/Admin/Tenants/components/Tenant');
const Admin = () => import(/* webpackChunkName: "admin" */ '@/views/Admin');
const Administrators = () => import(/* webpackChunkName: "administrators" */ '@/views/Admin/Administrators');
const TenantProfile = () => import(/* webpackChunkName: "tenant.profile" */ '@/views/Admin/Tenants/components/Profile');
const AdminList = () =>
  import(/* webpackChunkName: "administrators.list" */ '@/views/Admin/Administrators/components/UserList');

export default [
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    children: [
      {
        path: '',
        name: 'tenants',
        component: Tenants,
        children: [
          {
            path: '',
            name: 'tenantList',
            component: TenantList,
          },
          {
            path: '/admin/tenant/:id',
            name: 'tenant',
            component: Tenant,
            children: [
              {
                path: '',
                name: 'tenant.profile',
                component: TenantProfile,
              },
              {
                path: 'quickbooks',
                name: 'tenant.quickbooks',
                component: Quickbooks,
              },
            ],
          },
        ],
      },
      {
        path: 'tenant/quickbooks_redirect',
        name: 'tenant.quickbooks_redirect',
        component: QuickbooksRedirect,
      },
      {
        path: 'administrators',
        name: 'administrators',
        component: Administrators,
        children: [
          {
            path: '',
            name: 'administrators.list',
            component: AdminList,
          },
        ],
      },
    ],
    meta: {
      layout: LAYOUTS.ADMIN,
      title: 'Admin - eLuma Insight',
      permission: [PERMISSIONS.VIEW_ADMINISTRATION],
    },
  },
];
