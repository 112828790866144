import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { Component } from 'vue';
import { VueCookieNext } from 'vue-cookie-next';
import { can, useMasterTenant } from '@/composables';
import { PERMISSIONS } from '@/enum';
import { AdminService } from '@/services';
import {
  CityInterface,
  DefaultTenantInterface,
  OrganizationPayloadInterface,
  PersonCreateInterface,
  StoreInterface,
  TenantFilterInterface,
  TenantInterface,
  TenantPayloadInterface,
} from '@/types';
import { Profile, Quickbooks } from '@/views/Admin/Tenants/components';

interface TenantStateInterface extends StoreInterface {
  tenants?: TenantInterface[];
  tenant?: TenantInterface;
  impersonateTenant?: string;
  impersonatedTenant: boolean;
  newTenant: DefaultTenantInterface;
  newOrganization: OrganizationPayloadInterface;
  selectedTenantRoutes: TenantRoute[];
  newAdmin: PersonCreateInterface;
  quickbooksLink?: string;
  quickbooksParams: string;
  quickbooksTokenExists: boolean;
}

interface TenantRoute {
  to: string;
  label: string;
  component: Component;
  permission?: string[];
}

/**
 * The data ref object used to create the request payload.
 */
const defaultTenant = {
  name: '',
  street: '',
  city: {} as CityInterface,
  selectedTenantRoutes: [] as TenantRoute[],
  city_name: '',
  city_id: undefined,
  state_id: undefined,
  country_id: 1,
  state: '',
  zip: '',
  phone: '',
  timezone_id: undefined,
  email: '',
};

const defaultOrganization = {
  name: '',
  acronym: '',
  organization_type_id: undefined,
  tenant_id: undefined,
  org_type: '',
};

const defaultAdmin = {
  first_name: '',
  last_name: '',
  email: '',
  timezone_id: undefined,
  person_type_id: undefined,
  timezone: '',
};

export default defineStore('TenantStore', {
  state: (): TenantStateInterface => ({
    tenants: [],
    tenant: undefined,
    message: '',
    error: false,
    loading: false,
    newTenant: { ...defaultTenant },
    newOrganization: { ...defaultOrganization },
    newAdmin: { ...defaultAdmin },
    impersonateTenant: undefined,
    impersonatedTenant: false,
    selectedTenantRoutes: [] as TenantRoute[],
    quickbooksLink: undefined,
    quickbooksParams: '',
    quickbooksTokenExists: false,
  }),

  actions: {
    load() {
      this.error = false;
      this.message = '';
      this.loading = true;
    },
    catchError(e: AxiosResponse) {
      this.error = true;
      this.message = e.data.message;
      this.loading = false;
    },
    loaded(msg = '') {
      if (!this.error) {
        this.message = msg;
        this.loading = false;
      }
    },
    async getTenants(filters: TenantFilterInterface) {
      this.load();
      const request = await AdminService.getTenants(filters).catch(this.catchError);
      if (request) {
        this.tenants = request.data.data ?? undefined;
        this.loaded();
      }
    },
    /* Setting the routes for the selected tenant. */
    setRoutes() {
      this.selectedTenantRoutes = [
        {
          to: `/admin/tenant/${this.tenant?.id}`,
          label: 'Configuration',
          component: Profile,
        },
      ];
      //only add this route if its eLuma
      if (useMasterTenant().isMaster() && can([PERMISSIONS.QUICKBOOKS]))
        this.selectedTenantRoutes.push({
          to: `/admin/tenant/${this.tenant?.id}/quickbooks`,
          label: 'Quickbooks',
          component: Quickbooks,
        });
    },
    async getQuickbooksAPI() {
      this.load();
      const request = await AdminService.getQuickbooksAPI().catch(this.catchError);
      if (request) {
        this.quickbooksLink = request.data.url;
        this.quickbooksTokenExists = request.data.tokenExists;
      }
      this.loaded();
    },
    async revokeQuickbooks() {
      this.load();
      await AdminService.revokeQuickbooks().catch(this.catchError);
      this.loaded('Quickbooks successfully revoked');
    },
    async saveQuickbooksParams() {
      this.load();
      await AdminService.setQuickbooksAuthParams(this.quickbooksParams).catch(this.catchError);
      this.loaded();
    },
    async tokenExists() {
      this.load();
      const request = await AdminService.tokenExists().catch(this.catchError);
      if (request) {
        this.quickbooksTokenExists = request.data.tokenExists;
      }
      this.loaded('Quickbooks token revoked');
    },
    async getTenant(id: number) {
      this.loading = true;
      this.tenant = undefined;
      const request = await AdminService.getTenant(id).catch(this.catchError);
      if (request) {
        this.tenant = request.data.data ?? undefined;
        this.setRoutes();
        if (this.tenant?.location) this.tenant.location.city_name = this.tenant.location?.city?.name;
      }
      this.loaded();
    },
    async deleteTenant(id: number) {
      this.load();
      await AdminService.deleteTenant(id).catch(this.catchError);
      this.loaded('Tenant Deleted');
    },
    async restoreTenant(id: number) {
      this.load();
      await AdminService.restoreTenant(id).catch(this.catchError);
      this.loaded('Tenant successfully restored');
      if (this.message && this.error)
        this.doToast(this.message, this.error ? 'Error' : 'Success', this.error as boolean);
    },
    async updateTenant(payload: TenantPayloadInterface) {
      if (this.tenant) {
        this.load();
        await AdminService.updateTenant(payload).catch(this.catchError);
        this.loaded('Tenant updated');
      }
    },
    async updateTenantLocation(payload: TenantPayloadInterface) {
      if (this.tenant) {
        this.load();
        await AdminService.updateTenantLocation(payload).catch(this.catchError);
        this.loaded('Location Updated');
      }
    },
    async saveTenant() {
      if (this.newTenant) {
        this.load();
        this.newOrganization.org_type = this.newOrganization.org_type?.toLowerCase();
        const tenantOrgUser = {
          tenant: this.newTenant,
          organization: this.newOrganization,
          user: this.newAdmin,
        };
        await AdminService.saveTenant(tenantOrgUser).catch(this.catchError);
        this.loaded('Tenant saved');
      }
    },
    async getTokenToSwitchTenant(tenant: number) {
      this.loading = true;
      const response = await AdminService.switchTenant(tenant);
      this.loading = false;
      return response.data;
    },
    async leaveTenant(tenant: number) {
      this.loading = true;
      const access_tenant_token_cookie = VueCookieNext.getCookie('access_token_tenant');
      const accessToken = VueCookieNext.getCookie('accessToken');

      if (access_tenant_token_cookie && access_tenant_token_cookie !== accessToken) {
        await AdminService.leaveTenant(tenant);
      }
      VueCookieNext.removeCookie('access_token_tenant');
      this.loading = false;
    },
    resetTenantStore() {
      this.newTenant = { ...defaultTenant };
      this.newOrganization = { ...defaultOrganization };
      this.newAdmin = { ...defaultAdmin };
    },
  },
});
